import { render, staticRenderFns } from "./Pintek.vue?vue&type=template&id=3e7fe1c8&scoped=true"
import script from "./js/pintek.js?vue&type=script&lang=js&external"
export * from "./js/pintek.js?vue&type=script&lang=js&external"
import style0 from "./Pintek.vue?vue&type=style&index=0&id=3e7fe1c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e7fe1c8",
  null
  
)

export default component.exports