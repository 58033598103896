export default {
  components: {
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Close'),
    CheckMark: () => import(/* webpackChunkName: "icon" */ '@/components/icons/CheckMark'),
    Accordion: () => import(/* webpackChunkName: "icon" */ '@/components/accordion/Accordion')
  },
  data: () => ({
    content: [
      {
        id: 'quantity',
        text: 'How much can I borrow from Pintek?',
        active: false,
        submenu: {
          message: 'You can borrow up to 100% of the total cost with 0% interest.'
        }
      },
      {
        id: 'program',
        text: 'What are the programs that can apply for Pintek education loans?',
        active: false,
        submenu: {
          message: 'All students from all programs at G2Academy can apply for this education loan.'
        }
      },
      {
        id: 'registration',
        text: 'How long does the registration process with Pintek?',
        active: false,
        submenu: {
          message: 'The process is easy and fast in just 1 working day.'
        }
      },
      {
        id: 'info',
        text: 'Where can I get more information?',
        active: false,
        submenu: {
          message: 'You can contact info@pintek.id or 021-30060799 for additional information.'
        }
      }
    ]
  })
}
